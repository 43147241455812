import React from 'react'
import { Link as GatsbyLink, StaticQuery, graphql } from 'gatsby'
import { Flex, Box, NavLink as NavLinkBase } from 'rebass'
import styled from 'styled-components'

import ARROW_R from './../images/svgs/arrow-r.svg'

const NavLink = styled(NavLinkBase)`
  transition: color 0.2s ease-out;

  &:not(:last-child) {
    color: black;

    &::after {
      content: '';
      min-width: 1rem;
      min-height: 1rem;
      margin-left: 8px;
      background: url(${ARROW_R}) no-repeat;
      background-position: 50% 50%;
      color: ${props => props.theme.colors.coolGrey};
    }
  }

  &:hover {
    color: ${props => props.theme.colors.veryBlack};
  }

  &.active {
    pointer-events: none;
  }

  &:not(.active) {
    &:hover {
      color: ${props => props.theme.colors.trueBlue};
    }
  }
`

const Menu = ({ data }) => {
  const d = data.allContentfulPage.edges

  return (
    <Box mb={[16, 42]}>
      {d.map((k, i) => {
        k = k.node
        const slug = k.slug !== '/' ? `/${k.slug}` : `${k.slug}`
        const isBrowser = typeof window !== 'undefined'

        let currentPath = ''

        if (isBrowser) {
          currentPath = window.location.pathname
        }

        if (currentPath === slug) {
          let parent = ''

          if (k.contentfulparent) {
            parent = (
              <NavLink
                is={GatsbyLink}
                to={k.contentfulparent.slug}
                width="auto"
                pl="0"
                fontSize={0}
                fontWeight="normal"
                activeClassName="active"
                color="coolGrey"
              >
                {k.contentfulparent.title}
              </NavLink>
            )
          }

          return (
            <Flex key={k.id} style={{ position: 'relative' }}>
              {parent}
              <NavLink
                is={GatsbyLink}
                to={k.slug}
                width={[1]}
                pl="0"
                fontSize={0}
                fontWeight="normal"
                activeClassName="active"
                color="coolGrey"
              >
                {k.title}
              </NavLink>
            </Flex>
          )
        }
      })}
    </Box>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPage {
          edges {
            node {
              id
              title
              slug
              contentfulparent {
                title
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
)
