import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Flex, Box } from 'rebass'
import logo from './../images/svgs/logo-full.svg'

const Menu = ({ data }) => {
  return (
    <>
      <script
        type="text/javascript"
        src="https://identity.netlify.com/v1/netlify-identity-widget.js"
      />
      <Box mb={[18, 72]}>
        <Flex>
          <Heading>
            <a href="/" title="hy.am studios">
              <img src={logo} alt="hy.am studios" width="131" />
            </a>
          </Heading>
        </Flex>
      </Box>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulMenuMain {
          items {
            title
            slug
          }
        }
      }
    `}
    render={data => <Menu data={data} {...props} />}
  />
)
