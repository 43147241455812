import React from 'react'
import Helmet from 'react-helmet'
import { Provider, Box, Flex } from 'rebass'

import favicon from '../images/favicon.ico'
import '../styles/global'

import theme from '../styles/theme'
import config from '../utils/siteConfig'

import Navigation from './Navigation.js'
import Header from './Header.js'
import { IdentityProvider } from '../utils/identity.js'

export default class Template extends React.Component {
  render() {
    return (
      <IdentityProvider>
        <Provider theme={theme}>
          <Helmet>
            <title>{config.siteTitle}</title>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href={favicon} />
            <meta name="description" content={config.siteDescription} />
            <meta property="og:title" content={config.siteTitle} />
            <meta property="og:url" content={config.siteUrl} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={config.siteTitle} />
          </Helmet>
          <Box className="siteRoot" px={['9.4%']} py={['3.3%']}>
            <Header />
            <Flex
              className="siteContent"
              flexWrap="wrap"
              style={{ minHeight: '80vh' }}
              css={`
                @media (max-width: 767px) {
                  flex-direction: column;
                }
              `}
            >
              <Box width={['100%', '30%']}>
                <Navigation />
              </Box>
              <Box width={['100%', '70%']} pl={['0', '7.7%']}>
                {this.props.children}
              </Box>
            </Flex>
          </Box>
        </Provider>
      </IdentityProvider>
    )
  }
}
