import React from 'react'

// Helper Functions!!

export const fetchPage = (entryID, accessToken) => {
	const url = `/.netlify/functions/content/entry?entryID=${entryID}`
	return fetch(url, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	}).then(res => res.json())
}

export const getUserData = () => {
	if (!window.netlifyIdentity) {
		return Promise.reject(new Error(404))
	}
	const user = window.netlifyIdentity.currentUser()
	if (user) {
		return Promise.resolve(user.user_metadata)
	} else {
		return Promise.reject(new Error(401))
	}
}

export const getUserJWT = () => {
	if (!window.netlifyIdentity) {
		return Promise.reject(new Error(404))
	}
	const user = window.netlifyIdentity.currentUser()
	if (user) {
		return user.jwt()
	} else {
		return Promise.reject(new Error(401))
	}
}

export const fetchPreview = entryID =>
	getUserJWT()
		.then(jwt => fetchPage(entryID, jwt))
		.catch(error => Promise.reject(error))

// React Context!!

const { Provider, Consumer } = React.createContext('identity')

export const IdentityConsumer = Consumer
export class IdentityProvider extends React.Component {
	state = {
		user: null,
	}
	componentDidMount() {
		getUserData()
			.then(userData => {
				this.setState({
					user: userData,
				})
			})
			.catch(error => {}) // eslint-disable-line handle-callback-err
	}
	render() {
		return (
			<Provider value={this.state}>
				{this.props.children}
				<div id="netlify-modal" />
			</Provider>
		)
	}
}
