import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Button, Flex, NavLink } from 'rebass'

const Tags = ({ tags }) => (
  <Flex mb={3}>
    {tags.map(tag => <NavLink mr={2} key={tag} to={`/tag/${tag}`} is={GatsbyLink}>
      <Button>
        {tag}
      </Button>
    </NavLink>)}
  </Flex>
)

export default Tags
