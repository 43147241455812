import React from 'react'

export default class YoutubeEmbed extends React.Component {
	$el = React.createRef()
	state = {
		width: '100%',
		height: 0,
	}
	componentDidMount() {
		const r = this.$el.current.getBoundingClientRect()
		this.setState({
			height: (r.width * 9) / 16,
		})
	}
	render() {
		return (
			<iframe
				ref={this.$el}
				style={{
					width: this.state.width,
					height: this.state.height,
				}}
				src={this.props.src}
				frameBorder="0"
				allow="autoplay; encrypted-media"
				allowFullScreen
			/>
		)
	}
}
