import React, { createElement } from 'react'
import marksy from 'marksy/jsx'
import { Box, Heading, Text, Image, Link } from 'rebass'
import styled from 'styled-components'

import filterIframe from './filterIframe.js'
import YoutubeEmbed from '../components/YoutubeEmbed'
import SecretBlock from '../components/SecretBlock'

const Sep = styled(Box) ``

export const compile = marksy({
  createElement,
  components: {
    Youtube: YoutubeEmbed,
    SecretBlock,
  },
  elements: {
    hr({ children }) {
      return <Sep my={[2, 5]} />
    },
    h2({ children }) {
      return (
        <Heading
          is="h2"
          fontSize={[1, 3]}
          fontWeight="normal"
          mt={['28px', '36px']}
          mb={['18px', '26px']}
        >
          {children}
        </Heading>
      )
    },
    h3({ children }) {
      return (
        <Heading
          is="h3"
          fontSize={[1, 2]}
          fontWeight="normal"
          mt={['20px', '28px']}
          mb={['10px', '18px']}
        >
          {children}
        </Heading>
      )
    },
    h4({ children }) {
      return (
        <Heading
          is="h4"
          fontSize={[0, 1]}
          mt={[1, 4]}
          mb={[1]}
          css={`
            line-height: 1.57;
          `}
        >
          {children}
        </Heading>
      )
    },
    p({ children }) {
      return (
        <Text
          is="p"
          fontSize={[0, 1]}
          mb={[1]}
          css={`
            line-height: 1.57;
          `}
        >
          {children}
        </Text>
      )
    },
    ul({ children }) {
      return (
        <Box is="ul" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    ol({ children }) {
      return (
        <Box is="ol" pl={[18, 40]} fontSize={[0, 1]} mb={[16]}>
          {children}
        </Box>
      )
    },
    li({ children }) {
      return (
        <Text
          is="li"
          fontSize={[0, 1]}
          mb={[1, 8]}
          style={{
            listStyle: 'circle',
          }}
        >
          {children}
        </Text>
      )
    },
    a({ href, title, target, children }) {
      return (
        <Link
          href={href}
          alt={title}
          title={title}
          target={'_blank'}
          color="trueBlue"
        >
          {children}
        </Link>
      )
    },
    blockquote({ children }) {
      return (
        <Text
          is="blockquote"
          className="blockquote"
          css={`
            p {
              font-size: 35px;
              line-height: 1.46;
              text-align: center;
              margin-top: 82px;
              margin-bottom: 76px;
              color: #0019c5;
              @media (max-width: 767px) {
                font-size: 18px;
                margin: 40px 0;
              }
            }
          `}
        >
          {children}
        </Text>
      )
    },
    img({ src, alt }) {
      // as it's only those 3 formats, it's pretty straigthforward, but we could use whatever number of formats
      const regex = /.mp4|.ogg|.webm/g
      const isVideo = src.match(regex)

      if (isVideo) {
        return (
          <React.Fragment>
            <Box
              is="video"
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
              controls
            >
              <source src={src} />
            </Box>
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Image
              src={src}
              alt={alt}
              style={{
                width: '100%',
                maxWidth: '100%,',
              }}
              mt={['19px', '44px']}
              mb={['13px', '16px']}
            />
            <Text
              is="span"
              fontSize={[0]}
              pb={['35px', '54px']}
              color="coolGrey"
              textAlign="right"
              style={{ display: 'block' }}
            >
              {alt}
            </Text>
          </React.Fragment>
        )
      }
    },
  },
})
