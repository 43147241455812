import React, { createElement } from 'react'
import { graphql } from 'gatsby'

import { Box, Heading, Text, Image, Link } from 'rebass'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Breadcrumb from '../components/Breadcrumb'
import ArticleFooter from '../components/ArticleFooter'
import SecretBlock from '../components/SecretBlock'
import { fetchPreview } from '../utils/identity.js'
import filterIframe from '../utils/filterIframe.js'
import TeamPage from '../components/TeamPage.js'
import { compile } from '../utils/marksy.js'

class PageTemplate extends React.Component {
  state = {
    isQueryDone: false,
    querData: null,
  }
  tryGetNewData() {
    this.setState({ isQueryDone: false })
    fetchPreview(this.props.data.page.entryID)
      .then(res => {
        const output = { ...this.props.data }
        output.page.body.body = res.fields.body
        this.setState({
          isQueryDone: true,
          queryData: output,
        })
      })
      .catch(err => {
        console.log(err.message)
      })
  }
  componentDidMount() {
    this.tryGetNewData()
  }
  componentDidUpdate(pp, ps) {
    if (pp.data !== this.props.data) {
      this.tryGetNewData()
    }
  }
  render() {
    return PageTemplateRender({
      ...this.props,
      data: this.state.isQueryDone ? this.state.queryData : this.props.data,
    })
  }
}

const PageTemplateRender = ({ data, pageContext }) => {
  const d = data.page
  const compiled = compile(d.body ? filterIframe(d.body.body) : '')

  const noIntTitle =
    parseInt(d.title) >= -1 ? d.title.substr(d.title.indexOf(' ') + 3) : d.title

  return (
    <Layout>
      <SEO title={d.title} pageUrl={d.slug} />
      <Box is="article">
        <Breadcrumb />
        <Box>
          <Heading is="h1" fontSize={[2, 5]} fontWeight="normal" mb="14px">
            {noIntTitle}
          </Heading>
          {
            d.teamMembers ? <> <Box>{compiled ? compiled.tree : 'ERROR FETCHING DATA'}</Box> <TeamPage data={d.teamMembers} /> </> : <Box>{compiled ? compiled.tree : 'ERROR FETCHING DATA'}</Box>
          }
        </Box>
        <ArticleFooter
          author={
            d.author
              ? {
                name: `${d.author.name} - ${d.author.occupation}`,
                avatar: d.author.avatar.fixed.src,
                url: `mailto:${d.author.email}`
              }
              : null
          }
          previousArticle={pageContext.previous}
          nextArticle={pageContext.next}
          updatedAt={d.updatedAt}
          tags={d.tags}
        />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      entryID: contentful_id
      title
      slug
      tags
      updatedAt(formatString: "Do MMMM, YYYY")
      author {
        name
        occupation
        avatar {
          fixed(width: 72, quality: 100) {
            src
          }
        }
        email
      }
      body {
        body(remove_secret: true)
      }
      teamMembers {
        name
        occupation
        avatar {
          fixed(width: 256, quality: 100) {
            src
          }
        }
        bio {
          bio
        }
        email
      }
    }
  }
`

export default PageTemplate
