import { injectGlobal } from 'styled-components'
import * as fonts from './../fonts/fonts.js'

injectGlobal`
  @font-face {
    font-family:"Circular";
    src: url(${fonts.CircularBoldWOFF2}) format("woff2"),
    url(${fonts.CircularBoldWOFF}) format("woff"),
    url(${fonts.CircularBoldTTF}) format("opentype");
    font-style: bold;
    font-weight: 700;
  }

  @font-face {
    font-family:"Circular";
    src:url(${fonts.CircularBookWOFF2}) format("woff2"),
    url(${fonts.CircularBookWOFF}) format("woff"),
    url(${fonts.CircularBookTTF}) format("opentype");
    font-style: normal;
    font-weight: 400;
  }

  * { box-sizing: border-box; }
  body { margin: 0; }

  .iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 51%;
    background: #0019c5;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0; top: 0;
    }
  }
`
