import React from 'react'
import { Box } from 'rebass'

const SecretBlock = props => (
  <Box my={3} p={3} bg="trueBlue" color="white">
    A secret is blocked.
  </Box>
)

export default SecretBlock
