import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import sys from 'system-components'
import { themeGet } from 'styled-system'
import { Box, Heading, Text, Flex, Image } from 'rebass'
import Tags from './Tags'

import ARROW_L from './../images/svgs/arrow-l.svg'
import ARROW_R from './../images/svgs/arrow-r.svg'

export const Arrow = styled(Image) `
  display: inline-block;
  width: 9px;
  height: 11px;
  transform: translateY(1px);
  margin-left: ${p => (p.left ? 0 : 10)}px;
  margin-right: ${p => (p.right ? 0 : 10)}px;
`

export const Avatar = styled(Image) `
  width: ${p => p.size}px;
  min-width: ${p => p.size}px;
  height: ${p => p.size}px;
  border: solid 2px ${themeGet('colors.coolGrey')};
  border-radius: 99999px;
  overflow: hidden;
`

Avatar.defaultProps = {
  size: 72,
}

export const Name = sys(
  {
    is: Heading,
    fontSize: 1,
    fontWeight: 'normal',
  },
  {
    textDecoration: 'underline',
  }
)

export const Line = props => (
  <Box is="hr" css="border: 0;height: 2px;" bg="coolGrey" m={0} />
)

export const NavLink = props => (
  <Text
    is={Link}
    to={props.to}
    fontSize={0}
    color="coolGrey"
    css={`
      text-decoration: none;
      transition: color 0.2s ease-out;

      &:hover {
        color: black;
      }
    `}
  >
    {props.children}
  </Text>
)

const ArticleFooter = props => {
  const { author, previousArticle, nextArticle, updatedAt, tags } = props
  return (
    <Box mt={8 * 6} mb={[8 * 3, 0]}>
      {author && (
        <>
          <Text>Questions? Please contact the author for this page:</Text>
          <Flex mt={8 * 3} mb={8 * 5} alignItems="center">
            <Avatar src={author.avatar} />
            <Box ml={8 * 3} >
              <a href={author.url}>
                <Name color="black">
                  {author.name}
                </Name>
              </a>
              <Text mt={2}>Updated on: {updatedAt}</Text>
            </Box>
          </Flex>
        </>
      )}
      {tags && <Tags tags={tags} />}
      <Line height={2} backgroundColor="coolGrey" />
      <Flex mt={8 * 3} justifyContent="space-between">
        {previousArticle ? (
          <NavLink to={'/' + previousArticle.slug}>
            <Arrow left="true" src={ARROW_L} />
            {previousArticle.title}
          </NavLink>
        ) : (
            <div />
          )}
        {nextArticle ? (
          <NavLink to={'/' + nextArticle.slug}>
            {nextArticle.title}
            <Arrow right="true" src={ARROW_R} />
          </NavLink>
        ) : (
            <div />
          )}
      </Flex>
    </Box>
  )
}

export default ArticleFooter
