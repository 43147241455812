import React from 'react'
import { Box, Heading, Image, Text, Flex, Badge } from 'rebass'
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import sys from 'styled-components'
import { compile } from '../utils/marksy.js'
import ARROW_R from './../images/svgs/arrow-r--black.svg'

const Avatar = styled(Image) `
  width: ${p => p.size}px;
  min-width: ${p => p.size}px;
  height: ${p => p.size}px;
  border: solid 2px ${themeGet('colors.coolGrey')};
  border-radius: 99999px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: ${p => p.sizesmall}px;
    min-width: ${p => p.sizesmall}px;
    height: ${p => p.sizesmall}px;
  }
`

Avatar.defaultProps = {
  size: 128,
  sizesmall: 96
}

const TeamMember = styled(Flex) `
  /* border-bottom: 2px solid ${props => props.theme.colors.black}; */
  h4 {
    &::before {
      content: url(${ARROW_R});
      margin-right: 8px;
    }

    ~ p {
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }
  }
`

const TeamPage = props => {
  const d = props.data

  return (
    <Box my={[4]}>
      {
        d.map((k, i) => {
          const compiled = compile(k.bio ? k.bio.bio : '')

          return (
            <TeamMember
              key={i}
              flexWrap="wrap"
              flexDirection="column"
              mb={[1, 4]}
              pb={[1, 4]}
            >
              <Flex alignItems="center" flexDirection={["column", "initial"]} my={2}>
                <Box width={["100%", "auto"]} mb={[2, 0]}>
                  <Avatar src={k.avatar.fixed.src} />
                </Box>

                <Flex
                  pl={[0, 4]}
                  width={["100%", "auto"]}
                  flexDirection={["row", "column"]}
                  alignItems={["center", "initial"]}
                >
                  <Heading is="h3" fontSize={[2, 4]} mb={[1, 2]}>
                    {k.name}
                  </Heading>

                  <Badge css={`
                      box-shadow: 0 2px 4px 0 rgba(137,137,137,0.18);
                      width: fit-content;

                      @media (min-width:768px) {
                        margin-left: 0 !important;
                      }
                    `}
                    fontWeight="normal"
                    bg="white"
                    color="trueBlue"
                  >{k.occupation}</Badge>
                </Flex>
              </Flex>

              <Flex my={2}>
                <Box>{compiled ? compiled.tree : 'ERROR FETCHING DATA'}</Box>
              </Flex>
            </TeamMember>
          )
        })
      }
    </Box>
  )
}

export default TeamPage
