const filterIframe = (str, { tagname = 'iframe', replaceWith = '' } = {}) => {
  const regexp = new RegExp(`<${tagname}(.|\n)*?</${tagname}>`, 'gim')

  const wrapFrame = `<div className="iframe-container" key="${Math.random() *
    10}">${str.match(regexp)}</div>`

  return str.replace(regexp, wrapFrame)
}

module.exports = filterIframe
