module.exports = {
  breakpoints: ['768px'],
  fonts: {
    sans: 'Circular, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: [14, 18, 23, 28, 35, 44],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  colors: {
    /* Required: For PWA/Offline Settings */
    backgroundColor: '#FFFFFF',
    themeColor: '#0000FF',
    veryBlack: '#000000',
    trueBlue: '#0019c5',
    coolGrey: '#aeb0be',
    prettyPink: '#ec1266',
    /* Fun */
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
    /* The Rest */
  },
  radii: [0, 2, 4],
}
